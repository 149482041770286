import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isLoggedIn } from 'store/auth/selectors';
import { isAuthorised } from 'store/membership/selectors';
import { Cushion, MaxWidth, Space, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';
import ApplyForMembershipButton from './apply-for-membership-button';
import LoginButton from './login-button';

const AccessDenied = ({
  authorised,
  clubId,
  loggedIn,
  loginTranslationKey,
  messageTranslationKey,
  noHeading,
  onAuthorise,
}) => {
  useEffect(() => {
    if (authorised) {
      onAuthorise();
    }
  }, [authorised]);

  if (authorised) {
    return null;
  }

  return (
    <MaxWidth component="div" maxWidth={400} center>
      <Cushion all="medium" style={{ textAlign: 'center' }} component="div">
        {!noHeading && (
          <Space bottom="medium" component="div">
            <Typography preset="subtitle--large" color="dustygrey">
              <Trans i18nKey={messageTranslationKey}>Access denied</Trans>
            </Typography>
          </Space>
        )}
        {loggedIn ? (
          <ApplyForMembershipButton clubId={clubId} />
        ) : (
          <LoginButton translationKey={loginTranslationKey} />
        )}
      </Cushion>
    </MaxWidth>
  );
};

AccessDenied.defaultProps = {
  loginTranslationKey: 'common:login',
  messageTranslationKey: 'common:access_denied',
  noHeading: false,
  onAuthorise: () => {},
};

AccessDenied.propTypes = {
  authorised: PropTypes.bool.isRequired,
  clubId: PropTypes.number.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loginTranslationKey: PropTypes.string,
  messageTranslationKey: PropTypes.string,
  noHeading: PropTypes.bool,
  onAuthorise: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  authorised: isAuthorised(state, ownProps.clubId),
  loggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps)(AccessDenied);
